import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DSpaceService {

  constructor(private http: HttpClient) { }

  getPersonnes(query: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/eperson/epersons/search?query=${query}`);
  }

  getUserItems(userId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/eperson/items?userId=${userId}`);
  }

  getItemDetails(itemId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/eperson/item/details?itemId=${itemId}`);
  }

  getCollections(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/collections`);
  }

  getWorkflowItems(page: number = 1, size: number = 20, filters: any = {}): Observable<any> {
    const params = { page, size, ...filters };
    return this.http.get(`${environment.apiUrl}/rapports/workflowitems`, { params });
  }

  getWorkflowItemDetails(itemId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/rapports/workflowitems/details?itemId=${itemId}`);
  }

  getMappedCollection(itemId: string): Observable<string> {
    return this.http.get<any>(`${environment.apiUrl}/items?query=${itemId}`).pipe(
      map(response => {
        if (response?.name) {
          return response.name;
        } else {
          console.warn('Propriété name non trouvée dans la réponse.');
          return '';
        }
      })
    );
  }

  getFilters(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/rapports/filters`);
  }
}
